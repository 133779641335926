.input{
    width: 400px;
  }
  .question{
    display: grid;
    grid-template-columns: auto auto auto auto;
    border: 1px solid darkblue;
    padding: .5em;
  }
  .question .input{
    width: 240px;
  }
  .required-field{
    color: red;
  }
.field{
  margin: 1em 0;
}
.button{
  margin: 1em 0;
}

#login-card{
  width: 450px;
  background-color: dodgerblue;
  margin-left: 20%;
  margin-top: 5%;
  text-align: center;
}
#login-card .button{
  background-color: darkorange
}
.logout{
  display: flex;
}
.logout button{
  right: 1em;
  position: absolute;
}
.logout p{
  margin-top: 1.5em;
}
.error{
  color: red;
  font-size: 1.2em;
  position: relative;
  text-align: center;
}
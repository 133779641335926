.sidebar{
    background-color:#724fd6cf;
    width: 200px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: 1rem;
    box-shadow: 6px 0 5px -2px #b9b5b5;
}
.menu-list{
    /* list-style-type:none */
}
/* Link{
    margin: 1em 0;
    color: white;
    text-decoration: none;
} */
li{
    /* margin: 1em 0; */
    /* color: white; */
    /* text-decoration: none; */
}
/* li:hover{
    background-color: deeppink;
} */
/* .sidebar li:hover{
    background-color: deeppink;
} */
span{
    color: black;
}


.loading {
    -webkit-animation: spinAround 500ms infinite linear;
            animation: spinAround 500ms infinite linear;
    border: 12px solid #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 6em;
    width: 6em;
    position: relative;
    position: absolute !important;
    right: 0;
    left: 0;
    top: 30vh;
    margin: auto;
  }